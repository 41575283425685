import { useState, ReactNode, useEffect } from "react";

import { Fab, Box, SpeedDial, SpeedDialAction, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AutoFixOff from "@mui/icons-material/AutoFixOff";
import StraightenIcon from "@mui/icons-material/Straighten";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";

import { client } from "../RpcClient";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: 0,
    right: 0,
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: 0,
    left: 0,
  },
}));

interface Action {
  icon: string;
  name: string;
}

function actionToImg(action: Action): ReactNode {
  return (
    <Box
      component="img"
      sx={{ height: "56px" }}
      src={`imgs/icon${action.icon}room.webp`}
    />
  );
}

const actions: Action[] = [
  { icon: "beton", name: "Concrete" },
  { icon: "blank", name: "Blank" },
  { icon: "white", name: "White" },
  { icon: "wooden", name: "Wooden" },
];

function RoomChooser(props: {}) {
  const [curAction, setCurAction] = useState<Action>(actions[0]);
  return (
    <Box
      sx={{
        position: "relative",
        marginLeft: "15px",
        marginTop: "7.5px",
        height: "72px",
      }}
    >
      <StyledSpeedDial
        ariaLabel="Choose Your Room"
        icon={actionToImg(curAction)}
        direction="right"
      >
        {actions
          .filter((it) => it.icon !== curAction.icon)
          .map((action) => {
            const actionIndex = actions.findIndex(
              (it) => it.icon === action.icon
            );
            if (actionIndex === -1) {
              throw Error();
            }
            return (
              <SpeedDialAction
                key={action.name}
                icon={actionToImg(action)}
                tooltipTitle={action.name}
                FabProps={{
                  size: "large",
                  onClick: async () => {
                    await client.request("SofaSwitchToRoom", {
                      new_room_index: actionIndex,
                    });
                    setCurAction(action);
                  },
                }}
              />
            );
          })}
      </StyledSpeedDial>
    </Box>
  );
}

function DimensionInput(props: {
  id: string;
  label: string;
  defaultValue: string;
}) {
  const [num, setNum] = useState(props.defaultValue);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  return (
    <TextField
      margin="dense"
      id={props.id}
      label={props.label}
      variant="standard"
      type="text"
      sx={{ display: "block" }}
      InputProps={{
        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
      }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      onChange={(e) => handleChange(e)}
      value={num}
    />
  );
}

export default function ActionBar(props: {}) {
  const [autoSnap, setAutoSnap] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [roomSizeX, setRoomSizeX] = useState(0);
  const [roomSizeY, setRoomSizeY] = useState(0);
  const updateRoomDimensions = async () => {
    const roomSize = await client.request("SofaRoomSize", {});
    setRoomSizeX(roomSize.x);
    setRoomSizeY(roomSize.y);
  };
  useEffect(() => {
    updateRoomDimensions();
  }, []);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "160px",
        left: 0,
        bottom: 0,
        overflow: "none",
        pointerEvents: "initial",
      }}
    >
      <Fab
        sx={{ marginLeft: "15px", marginTop: "15px" }}
        onClick={async () => {
          setAutoSnap(
            (await client.request("SofaSwitchAutoSnap", {})).is_auto_snap
          );
        }}
        aria-label="autosnap"
      >
        {autoSnap ? <AutoFixHighIcon /> : <AutoFixOff />}
      </Fab>
      <Box sx={{ marginLeft: "15px", marginTop: "15px", display: "block" }}>
        <Fab onClick={async () => {}} aria-label="measure">
          <StraightenIcon />
        </Fab>
      </Box>
      <Box sx={{ marginLeft: "15px", marginTop: "15px", display: "block" }}>
        <Fab onClick={async () => {}} aria-label="help">
          <VideocamIcon />
        </Fab>
      </Box>
      <RoomChooser />
      <Box sx={{ marginLeft: "15px", marginTop: 0, display: "block" }}>
        <Fab
          sx={{ textTransform: "none", borderRadius: "5px", height: "30px" }}
          variant="extended"
          onClick={() => setOpen(true)}
          aria-label="help"
        >
          {roomSizeY}cm x {roomSizeX}cm
        </Fab>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Vnesi dimenzije tvoje sobe</DialogTitle>
        <DialogContent>
          <DimensionInput
            id="scale_y"
            label="Širina"
            defaultValue={roomSizeY.toString()}
          />
          <DimensionInput
            id="scale_x"
            label="Dolžina"
            defaultValue={roomSizeX.toString()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const newY = Number(
                (document.getElementById("scale_y") as HTMLInputElement).value
              );
              const newX = Number(
                (document.getElementById("scale_x") as HTMLInputElement).value
              );
              if (newY !== roomSizeY) {
                await client.request("SofaRoomScale", {
                  is_x: false,
                  wanted_size: newY,
                });
              }
              if (newX !== roomSizeX) {
                await client.request("SofaRoomScale", {
                  is_x: true,
                  wanted_size: newX,
                });
              }
              updateRoomDimensions();
              setOpen(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
