export const config: {
  signalsProtocol?: string;
  matchmakerURL?: string;
  filesServerOverride?: string;
  avatarsPortEnding: string;
} = {
  signalsProtocol: "wss://",
  matchmakerURL: "https://matchmaker.pixels.reeba.io",
  avatarsPortEnding: "1",
};
