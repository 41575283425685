import * as React from "react";

import CircularProgressWithLabel from "./CircularProgressWithLabel";

export default function CircularStatic(props: { time: number }) {
  const [progress, setProgress] = React.useState(0);
  const refreshRate = 100;
  const step = 100 / (props.time / refreshRate);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + step;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, refreshRate);
    return () => {
      clearInterval(timer);
    };
  }, [step]);

  return <CircularProgressWithLabel value={progress} />;
}
