import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ValidationGroup, Validate, useValidation } from "mui-validate";

import { apps } from "./Apps";

export default function SignUp(props: { setUserId: (userId: number) => void }) {
  const params = useParams();
  const app = apps.find((it) => params.appId === it.link);
  if (app === undefined) {
    throw Error();
  }
  if (app.spaceId === undefined) {
    throw Error();
  }
  const [validation, setValidation] = useState<"silent" | "noisy">("silent");
  const [emailUseChecked, setEmailUseChecked] = useState(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const ggM = (key: string) => {
      const val = data.get(key);
      if (typeof val === "string") {
        return val.length > 0 ? val : undefined;
      } else {
        return undefined;
      }
    };
    const gg = (key: string) => {
      const val = ggM(key);
      if (val === undefined) {
        throw Error(`undefined value for key: ${key}`);
      }
      return val;
    };
    try {
      const user = {
        name: gg("name"),
        lastname: gg("lastname"),
        email: gg("email"),
        company: ggM("company"),
        country: ggM("country"),
        email_marketing: emailUseChecked,
        spaces_id: app.spaceId,
      };
      try {
        const response = await fetch(
          `https://api.reeba.io/items/anon_visitors`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(user),
          }
        );
        const data = await response.json();
        props.setUserId(data.data.id);
      } catch (err) {
        console.error(err);
      }
    } catch (_) {
      console.log("SETTING VALIDATION to noisy");
      setValidation("noisy");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUseChecked(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        postion: "absolute",
        top: 0,
        left: 0,
        overflowX: "none",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <AccountCircleOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Enter Visitor Data
            </Typography>
            <ValidationGroup>
              <Box
                component="form"
                // noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Validate name="name" required>
                      <TextField
                        autoComplete="given-name"
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="First Name"
                        autoFocus
                      />
                    </Validate>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Validate name="lastname" required>
                      <TextField
                        required
                        fullWidth
                        id="lastname"
                        label="Last Name"
                        name="lastname"
                        autoComplete="family-name"
                      />
                    </Validate>
                  </Grid>
                  <Grid item xs={12}>
                    <Validate
                      name="email"
                      required
                      regex={
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      }
                    >
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Validate>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="company"
                      label="Company"
                      id="company"
                      autoComplete="company"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="country"
                      label="Country"
                      id="country"
                      autoComplete="country"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={emailUseChecked}
                          onChange={handleEmailChange}
                          value="allowExtraEmails"
                          color="primary"
                        />
                      }
                      label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Next
                </Button>
              </Box>
            </ValidationGroup>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
