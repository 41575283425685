import { useState, useContext } from "react";

import { Box, Typography, TextField } from "@mui/material";

import {
  ElementsData,
  TypesData,
  MaterialsData,
  CurrentElements,
  CurPreset,
  UserTypes,
} from "./DataTypes";
import { client } from "../RpcClient";
import MaterialChooser from "./MaterialChooser";
import ElementChooser from "./ElementChooser";
import Price from "./Price";
import TextOptionsChooser from "./TextOptionsChooser";
import { Trans } from "../Translations";

function changeColorTo(newColorId: number, newColorExternalId: string) {
  (window as any).reeWasChangeColorAll = true;
  client.request("SofaChangeColorAll", {
    color_id: newColorId,
    color_external_id: newColorExternalId,
  });
}

interface CoverOption {
  id: number;
  name: string;
}

export default function Sidebar(props: {
  setLoading: (isLoading: boolean) => void;
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  setCurMaterial: (id: number) => void;
  curColors: { [key: number]: number };
  setCurColors: (newValue: { [key: number]: number }) => void;
  typesData: TypesData | undefined;
  curType: number;
  curPreset: CurPreset | undefined;
  curElements: CurrentElements;
  elementsData: ElementsData | undefined;
  userTypes: UserTypes;
  setUserTypes: (newUserTypes: UserTypes) => void;
}) {
  const { _ } = useContext(Trans);
  const coversData = [
    { id: 1, name: "Fiksne prevleke" },
    { id: 2, name: "Snemljive prevleke" },
  ];
  const [curCover, setCurCover] = useState(coversData[1]);
  const legsData = [
    { id: 1, name: "Svetle (17 cm)" },
    { id: 2, name: "Svetle (21 cm)" },
    { id: 3, name: "Temne (17 cm)" },
    { id: 4, name: "Temne (21 cm)" },
  ];
  const [curLeg, setCurLeg] = useState(legsData[0]);
  const curTypeObj = props.typesData?.data.find(
    (it) => it.id === props.curType
  );
  const curColor = props.materialsData?.data
    ?.find((it) => it.id === props.curMaterial)
    ?.colors.find((it) => it.id === props.curColors[props.curMaterial]);
  if (curTypeObj === undefined) {
    return (
      <Box
        sx={{
          pointerEvents: "initial",
          position: "absolute",
          top: "160px",
          right: 0,
          width: "500px",
          bottom: 0,
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src="imgs/up-arrow.webp"
            sx={{
              width: "130px",
            }}
          />
          <Typography
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "1.5em",
              fontWeight: 300,
              textAlign: "center",
              margin: "30px",
              color: "#000",
            }}
          >
            Izberi si svoj željeni sedežni sestav iz pasice na vrhu spletne
            strani.
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          pointerEvents: "initial",
          position: "absolute",
          top: "20px",
          right: 0,
          width: "500px",
          bottom: 0,
          overflowY: "auto",
          marginBottom: "95px",
        }}
      >
        <Box sx={{ marginLeft: "25px", marginRight: "25px" }}>
          {(() => {
            const curPreset = props.curPreset;
            if (curPreset !== undefined) {
              if (curPreset.isUserPreset) {
                const curPresetObj = props.userTypes[
                  props.curType
                ]?.presets.find((it) => it.id === curPreset.id);
                if (curPresetObj) {
                  return (
                    <TextField
                      label="Ime tvoje sedezne"
                      variant="outlined"
                      value={curPresetObj.name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const newName = event.target.value;
                        const newUserTypes = { ...props.userTypes };
                        for (const preset of newUserTypes[props.curType]
                          .presets) {
                          if (preset.id === curPreset.id) {
                            preset.name = newName;
                            break;
                          }
                        }
                        props.setUserTypes(newUserTypes);
                      }}
                      sx={{
                        marginTop: "5px",
                        color: "#000",
                        "& .MuiOutlinedInput-input": {
                          fontFamily: '"Montserrat", sans-serif',
                          fontSize: "1.5em",
                          fontWeight: 600,
                          textAlign: "center",
                        },
                      }}
                    />
                  );
                }
              } else {
                const curPresetObj = curTypeObj?.presets.find(
                  (it) => it.id === curPreset.id
                );
                if (curPresetObj) {
                  return (
                    <Typography
                      sx={{
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: "2.5em",
                        fontWeight: 600,
                        marginBottom: "0.3em",
                        color: "#000",
                      }}
                    >
                      {curPresetObj.name}
                    </Typography>
                  );
                }
              }
            }
            return false;
          })()}
          <MaterialChooser
            materialsData={props.materialsData}
            curMaterial={props.curMaterial}
            setCurMaterial={props.setCurMaterial}
            curColors={props.curColors}
            setCurColors={props.setCurColors}
            changeColorTo={changeColorTo}
            isPopup={false}
            curElements={props.curElements}
          />
          <ElementChooser
            curType={curTypeObj}
            curColor={curColor}
            elementsData={props.elementsData}
          />
        </Box>
        <Box
          sx={{ marginLeft: "25px", marginRight: "25px", marginTop: "-0.5em" }}
        >
          <TextOptionsChooser<CoverOption>
            mainTitle={_("Prevleka")}
            options={coversData}
            getTitle={(option) => option.name}
            getId={(option) => option.id}
            selectedOption={curCover}
            setSelectedOption={(option) => setCurCover(option)}
            isPopup={false}
            columns={2}
          />
        </Box>
        <Box
          sx={{ marginLeft: "25px", marginRight: "25px" }}
        >
          <TextOptionsChooser<CoverOption>
            mainTitle={_("Barva nogic")}
            options={legsData}
            getTitle={(option) => option.name}
            getId={(option) => option.id}
            selectedOption={curLeg}
            setSelectedOption={(option) => setCurLeg(option)}
            isPopup={false}
            columns={2}
          />
        </Box>
        <Price
          setLoading={props.setLoading}
          curElements={props.curElements}
          elementsData={props.elementsData}
          materialsData={props.materialsData}
        />
      </Box>
    );
  }
}
