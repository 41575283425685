export interface StreamApp {
  name: string;
  link: string;
  appName: string;
  exe: string;
  firstArg: string;
  spaceId?: number;
  loginEnabled?: boolean;
  avatarsEnabled?: boolean;
}

export const apps: StreamApp[] = [
  {
    name: "Reeba Configurator",
    link: "configurator-demo",
    appName: "ReebaConfigurator/LinuxNoEditor",
    exe: "ReebaConfigurator/Binaries/Linux/ReebaConfigurator",
    firstArg: "ReebaConfigurator",
  },
  {
    name: "Reeba DEMO",
    link: "reeba-demo",
    appName: "GalleryBogdana/LinuxNoEditor",
    exe: "ReebaConfigurator/Binaries/Linux/ReebaConfigurator",
    firstArg: "ReebaConfigurator",
  },
  {
    name: "Small Project",
    link: "small-project",
    appName: "SmallProject/LinuxNoEditor",
    exe: "SmallProject/Binaries/Linux/SmallProject",
    firstArg: "SmallProject",
  },
  {
    name: "Gasper",
    link: "reeba-configurator",
    appName: "Gasper/LinuxNoEditor",
    exe: "Gasper/Binaries/Linux/Gasper-Linux-Shipping",
    firstArg: "Gasper",
    spaceId: 4,
  },
  {
    name: "COP27",
    link: "cop27",
    appName: "Cop27/LinuxNoEditor",
    exe: "expo_demo/Binaries/Linux/expo_demo-Linux-Shipping",
    firstArg: "expo_demo",
    loginEnabled: true,
    spaceId: 5,
    avatarsEnabled: true,
  },
  {
    name: "COP27-DEV",
    link: "cop27-dev",
    appName: "Cop27-DEV/LinuxNoEditor",
    exe: "expo_demo/Binaries/Linux/expo_demo-Linux-Shipping",
    firstArg: "expo_demo",
    loginEnabled: true,
    spaceId: 5,
    avatarsEnabled: true,
  },
  {
    name: "Art4Climate",
    link: "art4climate",
    appName: "Art4Climate/LinuxNoEditor",
    exe: "ReebaVRStarter/Binaries/Linux/ReebaVRStarter-Linux-Shipping",
    firstArg: "ReebaVRStarter",
  },
  {
    name: "LiveStreamingDebug",
    link: "live-streaming-debug",
    appName: "LiveStreamingDebug/LinuxNoEditor",
    exe: "LiveStreamingDebug/Binaries/Linux/LiveStreamingDebug-Linux-Debug",
    firstArg: "LiveStreamingDebug",
  },
  {
    name: "FLS",
    link: "fls",
    appName: "FLS/LinuxNoEditor",
    exe: "Gasper/Binaries/Linux/Gasper-Linux-Shipping",
    firstArg: "Gasper",
  },
  {
    name: "Feydom",
    link: "feydom",
    appName: "Feydom/Linux",
    exe: "Feydom/Binaries/Linux/Feydom",
    firstArg: "Feydom",
    spaceId: 6,
  },
  {
    name: "Feydom Dev",
    link: "feydom-dev",
    appName: "FeydomDev/Linux",
    exe: "Feydom/Binaries/Linux/Feydom",
    firstArg: "Feydom",
    spaceId: 6,
  },
];
