import { useState, useEffect } from "react";

import { Fab, Box } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";

import MapNavigation from "../MapNavigation";
import CustomizeObject from "../CustomizeObject";

export default function WindowIndex(props: {}) {
  const [customizeObject, setCustomizeObject] = useState<any>(null);
  useEffect(() => {
    (window as any).customize_object = function (data: any) {
      setCustomizeObject(data);
    };
  }, []);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
      }}
    >
      {!isDetail && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "none",
            pointerEvents: "initial",
          }}
        >
          <Fab
            variant="extended"
            sx={{ marginLeft: "15px", marginTop: "15px" }}
            onClick={() => setOpen(true)}
            aria-label="windows and doors map"
          >
            <MapIcon sx={{ marginRight: 1 }} />
            Seznam oken in vrat
          </Fab>
        </Box>
      )}
      <MapNavigation open={open} setOpen={setOpen} />
      {customizeObject !== null && (
        <CustomizeObject
          data={customizeObject}
          setData={setCustomizeObject}
          isDetail={isDetail}
          setIsDetail={setIsDetail}
        />
      )}
    </Box>
  );
}
