import { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";

interface Stand {
  id: number;
  title: string;
  logo: string;
}

interface Category {
  id: number;
  title: string;
  sort: number;
  stands: Stand[];
}

function BasicList() {
  const [categories, setCategories] = useState<Category[]>();
  const getApiData = async () => {
    const response: {
      data: Array<{
        id: number;
        category: string;
        display_category: {
          id: number;
          sort: number;
          translations: Array<{
            languages_code: string;
            title: string;
          }>;
        };
        translations: Array<{
          languages_code: string;
          title: string;
          logo: string;
        }>;
      }>;
    } = await fetch(
      'https://api.reeba.io/items/stands?filter={"spaces_id":{"name":{"_eq":"Marseille"}},"status":{"_eq":"published"}}&fields=*,translations.*,brochures.*,brochures.translations.*,slideshow.slides.translations.*,display_category.id,display_category.sort,display_category.translations.*&sort=sort'
    ).then((response) => response.json());
    const newCategories: Category[] = [];
    for (const stand of response.data) {
      let categoryIndex = -1;
      let index = 0;
      for (const category of newCategories) {
        if (category.id === stand.display_category.id) {
          categoryIndex = index;
          break;
        }
        index++;
      }
      if (categoryIndex === -1) {
        categoryIndex =
          newCategories.push({
            id: stand.display_category.id,
            sort: stand.display_category.sort,
            title:
              (stand.display_category.translations.find(
                (trans) => trans.languages_code === "fr-FR"
              )?.title ?? "") +
              " / " +
              (stand.display_category.translations.find(
                (trans) => trans.languages_code === "en-US"
              )?.title ?? ""),
            stands: [],
          }) - 1;
      }
      newCategories[index].stands.push({
        id: stand.id,
        title: stand.translations[0].title,
        logo: stand.translations[0].logo,
      });
    }
    newCategories.sort((a, b) => a.sort - b.sort);
    for (let i = 0; i < newCategories.length; ++i) {
      newCategories[i].stands.sort((a, b) => (a.title > b.title ? 1 : -1));
    }
    let extWindow = window as any;
    extWindow.eneCategories = newCategories;
    setCategories(newCategories);
  };
  useEffect(() => {
    let extWindow = window as any;
    if (extWindow.eneCategories === undefined) {
      getApiData();
    } else {
      setCategories(extWindow.eneCategories);
    }
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="h4" gutterBottom component="div">
        LISTE DES EXPOSANTS / LIST OF EXHIBITORS
      </Typography>
      {
        categories ? (
          categories.map((category) => (
            <Box>
              <List>
                <ListSubheader component="div" id="nested-list-subheader">
                  {category.title}
                </ListSubheader>
                {category.stands.map((stand) => {
                  const url = `https://api.reeba.io/assets/${stand.logo}?width=120&height=40&fit=inside&format=png`;
                  return (
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          let extWindow = window as any;
                          extWindow.player.sendSDKMessage({
                            EneAction: "go_to_stand",
                            value: stand.id,
                          });
                          extWindow.current_popup.close();
                        }}
                      >
                        <ListItemIcon>
                          <Box sx={{ width: 120 }}>
                            <img
                              src={url}
                              srcSet={url}
                              alt={stand.title}
                              loading="lazy"
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary={stand.title} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <Divider />
            </Box>
          ))
        ) : (
          <List>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
              <ListItem disablePadding>
                <ListItemIcon>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 120,
                      height: 60,
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  </Box>
                </ListItemIcon>
                <ListItemText>
                  <Skeleton animation="wave" width="70%" height={15} />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )
        /*(
    <Box
    sx={{
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: 500,
    justifyContent: "center",
    alignItems: "center",
    }}
    >
    <CircularProgress sx={{ color: "#c2c2c2" }} />
    </Box>
    )*/
      }
    </Box>
  );
}

function Map() {
  return BasicList();
}

export default Map;
