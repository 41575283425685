import { useState, useEffect, createContext, PropsWithChildren } from "react";

const gettextJs = (window as any).i18n();

// TODO get correct language from somewhere
const defaultLocale = "sl_SI";

interface TranslationsContextObject {
  locale: string;
  changeLocale: (newLocale: string) => Promise<void>;
  _: (msgid: string, ...args: any[]) => string;
}

export const Trans = createContext<TranslationsContextObject>({
  locale: "xx_XX",
  changeLocale: async (newLocale) => {},
  _: (str) => str,
});

async function loadLanguage(locale: string): Promise<void> {
  if (locale !== "en_US") {
    gettextJs.loadJSON(
      await fetch(`translations/${locale}.po.json`).then((response) =>
        response.json()
      ),
      "messages"
    );
  }
  gettextJs.setLocale(locale);
}

interface TranslationsProps {}
export default function Translations(
  props: PropsWithChildren<TranslationsProps>
) {
  const [locale, setLocale] = useState<string>(defaultLocale);
  useEffect(() => {
    loadLanguage(defaultLocale);
  }, []);
  return (
    <Trans.Provider
      value={{
        locale: locale,
        changeLocale: async (newLocale: string) => {
          await loadLanguage(newLocale);
          setLocale(newLocale);
        },
        _: (msgid, ...args) => {
          return gettextJs.gettext(msgid, ...args);
        },
      }}
    >
      {props.children}
    </Trans.Provider>
  );
}
