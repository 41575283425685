import { JSONRPCClient, TypedJSONRPCClient } from "json-rpc-2.0";

import { ReeRPCMethods } from "./generated/ReeRPCMethods";

/*
type Methods = {
  sofa_restore(params: { elements: RestoreCurrentElement[] }): void;
  sum(params: { x: number; y: number }): number;
};
*/

const extWindow = window as any;

export const client: TypedJSONRPCClient<ReeRPCMethods> = new JSONRPCClient(
  async (jsonRPCRequest) => {
    console.log(jsonRPCRequest);
    extWindow.emitUIInteraction(jsonRPCRequest);
  }
);

extWindow.reeOnRpcResponse = client.receive.bind(client);

/*
client
  .request("echo", { message: "hello" })
  .then((result) => console.log(result));
client.request("sum", { x: 1, y: 2 }).then((result) => console.log(result));
*/
// These result in type error
// client.request("ech0", { message: "hello" }); // typo in method name
// client.request("echo", { messagE: "hello" }); // typo in param name
// client.request("echo", { message: 123 }); // message param must be string
// client
//   .request("echo", { message: "hello" })
//   .then((result: number) => console.log(result)); // return type must be string
