import { useEffect, useState, useRef } from "react";

import { Button, Box, Typography } from "@mui/material";

import { MaterialsData, ElementsData, CurrentElements } from "./DataTypes";

export default function Price(props: {
  setLoading: (isLoading: boolean) => void;
  curElements: CurrentElements;
  materialsData: MaterialsData | undefined;
  elementsData: ElementsData | undefined;
}) {
  const [price, setPrice] = useState<number>();
  const prevCurElements = useRef<CurrentElements>({ elements: [] });
  useEffect(() => {
    let changed = false;
    if (
      prevCurElements.current.elements.length ===
      props.curElements.elements.length
    ) {
      const els1 = prevCurElements.current.elements;
      const els2 = props.curElements.elements;
      for (const el2 of els2) {
        if (els1.findIndex((it) => it.i === el2.i && it.c === el2.c) === -1) {
          changed = true;
          break;
        }
      }
    } else {
      changed = true;
    }
    if (changed) {
      prevCurElements.current = props.curElements;
      const getApiData = async () => {
        const prices = await Promise.all(
          props.curElements.elements.map((element) =>
            fetch(
              `https://api.reeba.io/items/sofa_prices?filter={"sofa_elements_id":{"_eq":${element.i}},"sofa_colors_id":{"_eq":${element.c}}}&fields=price`
            ).then((response) => response.json())
          )
        );
        let totalPrice = 0;
        for (const price of prices) {
          if (price.data.length !== 1) {
            throw Error();
          }
          totalPrice += Number(price.data[0].price);
        }
        setPrice(totalPrice);
      };
      getApiData();
    }
  }, [props]);
  return (
    <Box
      sx={{
        boxShadow: 5,
        position: "fixed",
        bottom: 0,
        right: 0,
        width: "500px",
        height: "95px",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          marginLeft: "15px",
          fontWeight: 600,
          fontSize: "2em",
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        {price &&
          new Intl.NumberFormat("sl-SI", {
            style: "currency",
            currency: "EUR",
          }).format(price)}
      </Typography>
      <Button
        onClick={async () => {
          props.setLoading(true);
          const res = await fetch("add-to-cart.php", {
            credentials: "same-origin",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              products: props.curElements.elements.map((element) => {
                const eData = props.elementsData;
                const mData = props.materialsData;
                if (eData === undefined || mData === undefined) {
                  throw Error();
                }
                const sofaElement = eData.data.find(
                  (it) => it.id === element.i
                );
                if (sofaElement === undefined) {
                  throw Error();
                }
                let elMaterial;
                let elColor;
                for (const material of mData.data) {
                  for (const color of material.colors) {
                    if (color.id === element.c) {
                      elColor = color.external_id;
                      break;
                    }
                  }
                  if (elColor !== undefined) {
                    elMaterial = material.external_id;
                    break;
                  }
                }
                if (elMaterial === undefined || elColor === undefined) {
                  throw Error();
                }
                return {
                  slug: sofaElement.external_id,
                  material: elMaterial,
                  color: elColor,
                };
              }),
            }),
          });
          console.log(await res.text());
          if (res.ok) {
            window.location.href = "/kosarica";
          }
        }}
        sx={{
          "::after": {
            content: '""',
            marginLeft: "10px",
            width: "20px",
            height: "20px",
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg id='bag' xmlns='http://www.w3.org/2000/svg' width='19.809' height='20' viewBox='0 0 19.809 20'%3E%3Cpath id='rect1979' d='M11.9,292.65a4.013,4.013,0,0,0-4,4v1H3a1,1,0,0,0-1,1v10a4.013,4.013,0,0,0,4,4H17.809a4.013,4.013,0,0,0,4-4v-10a1,1,0,0,0-1-1H15.9v-1A4.013,4.013,0,0,0,11.9,292.65Zm0,2a1.974,1.974,0,0,1,2,2v1h-4v-1A1.974,1.974,0,0,1,11.9,294.65Zm-7.9,5H7.9v2.979a1,1,0,0,0,2,0V299.65h4v2.979a1,1,0,0,0,2,0V299.65h3.9v9a1.974,1.974,0,0,1-2,2H6a1.974,1.974,0,0,1-2-2Z' transform='translate(-2 -292.65)' fill='%23fff' /%3E%3C/svg%3E\")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
          },
          ":hover": {
            backgroundColor: "#000",
          },
          marginRight: "15px",
          padding: "20px",
          color: "white",
          backgroundColor: "#a21318",
          fontWeight: "bold",
          border: "none",
          borderRadius: "7px",
          textTransform: "uppercase",
          transition: "all 0.3s",
          height: "60px",
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        Dodaj v košarico
      </Button>
    </Box>
  );
}
