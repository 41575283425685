import { isPixels } from "./Util";

let curResizeFunction:
  | ((
      width: number,
      height: number,
      setRootAlignRight: (val: boolean) => void
    ) => void)
  | null = null;

let triggerResizeFunction = function () {
  console.error("triggerResizeFunction called before init finished !!!");
};

export function setResizeFunction(
  newFunction: (
    width: number,
    height: number,
    setRootAlignRight: (val: boolean) => void
  ) => void
) {
  curResizeFunction = newFunction;
  triggerResizeFunction();
}

export function customizeObjectResize(
  windowWidth: number,
  windowHeight: number,
  setRootAlignRight: (val: boolean) => void
): void {
  let width = 6.5;
  let height = 1.0;
  let align = "top_right";
  let widthUnits = "px";
  const extWindow = window as any;
  if (windowHeight > windowWidth) {
    width = 1.0;
    height = 0.44;
    align = "bottom_left";
    widthUnits = "vw";
  }
  if (isPixels()) {
    const root = document.getElementById("root");
    if (root === null) {
      console.error("Couldn't find root element");
    } else {
      root.setAttribute(
        "style",
        `position: absolute; ${
          align === "top_right" ? "top: 0; right: 0;" : "bottom: 0; left: 0;"
        } width: ${width * 100}${widthUnits}; height: ${
          height * 100
        }vh; margin: 0; padding: 0;`
      );
    }
  } else {
    extWindow.ue.reepui.show();
    // TODO support left, top, bottom, right ...
    extWindow.ue.reepui.setsize(width, height);
  }
  setRootAlignRight(align === "top_right");
}

export const setState: {
  loading: ((val: boolean) => void) | null;
  serverStartTime: ((val: number | null) => void) | null;
} = {
  loading: null,
  serverStartTime: null,
};

export function setupPixelStreamingActions(
  setCustomizeObject: (val: any) => void,
  setRootAlignRight: (val: boolean) => void,
  setLoading: (val: boolean) => void,
  setServerStartTime: (val: number | null) => void
) {
  const onPixelStreamingAction = function (dataStr: any) {
    const data = JSON.parse(dataStr);
    console.log(data);
    console.log(data.action);
    const extWindow = window as any;
    /*
    if (data.action === "customize_object") {
      curResizeFunction = customizeObjectResize;
      if (isPixels()) {
        customizeObjectResize(
          window.innerWidth,
          window.innerHeight,
          setRootAlignRight
        );
      } else {
        // TODO get the height and width of window from somewhere
        // maybe cache it ...
        // and pass it to resize() function here
      }
      console.log("RENDERING nowwwww");
      setCustomizeObject(data);
    } else */ if (data.p !== undefined) {
      const client = extWindow.reeClient;
      if (client !== undefined && client !== null) {
        client.updatePosition(
          data.p[0],
          data.p[1],
          data.p[2],
          data.p[3],
          data.p[4],
          data.p[5],
          data.p[6],
          data.p[7],
          data.p[8]
        );
      }
    } else if (extWindow[data.action] !== undefined) {
      extWindow[data.action](data);
    } else if (data.jsonrpc === "2.0") {
      console.log("Calling ree on rpc response data with: ", data);
      extWindow.reeOnRpcResponse(data);
    } else {
      console.log("NOTTTT RENDERING nowwwww");
    }
  };
  let extWindow = window as any;
  setState.loading = setLoading;
  setState.serverStartTime = setServerStartTime;
  extWindow.setState = setState;
  if (isPixels()) {
    let extWindow = window as any;
    extWindow.addResponseEventListener(
      "pixel_streaming_action",
      onPixelStreamingAction
    );
    const triggerResize = function () {
      if (curResizeFunction !== null) {
        curResizeFunction(
          window.innerWidth,
          window.innerHeight,
          setRootAlignRight
        );
      }
    };
    window.addEventListener("resize", triggerResize);
    triggerResizeFunction = triggerResize;
    extWindow.load();
  } else {
    extWindow.reeHandleAction = onPixelStreamingAction;
    // TODO call the curResizeFunction when the ue4 window is resized...
  }
}
