import { useParams } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  useTheme,
  Theme,
} from "@mui/material/styles";
import Button from "@mui/material/Button";

import { apps } from "./Apps";
import { setupPixelStreamingActions } from "./PixelStreaming";
import CustomizeObject from "./CustomizeObject";
import CircularStatic from "./CircularStatic";
import { config } from "./Config";
import SofaIndex from "./Sofas/SofaIndex";
import WindowIndex from "./Windows/WindowIndex";

function renderImage(imgSrc: null | string) {
  return imgSrc === null ? (
    <Box
      sx={{
        width: "0",
        height: "0",
      }}
    ></Box>
  ) : (
    <img
      alt="Loading logo"
      style={{ maxHeight: "40vh", maxWidth: "40vw", marginBottom: "50px" }}
      src={imgSrc}
    />
  );
}

function renderBox(children: ReactNode) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Box>
  );
}

export default function Player(props: { userId: number | null }) {
  const params = useParams();
  const [customizeObject, setCustomizeObject] = useState<any>(null);
  const [isRootAlignRight, setRootAlignRight] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverStartTime, setServerStartTime] = useState<number | null>(null);
  const [theme, setTheme] = useState<Theme>(useTheme());
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  // const appId = "reeba-configurator";
  const app = apps.find((it) => params.appId === it.link);
  const [isWaitingForAvatarSelection, setWaitingForAvatarSelection] =
    useState<boolean>(app !== undefined && app.avatarsEnabled === true);
  console.log("is waiting ture: ", isWaitingForAvatarSelection);
  useEffect(() => {
    const extWindow = window as any;
    if (app !== undefined) {
      extWindow.reeAppName = app.appName;
      extWindow.reeAppExe = app.exe;
      extWindow.reeFirstArg = app.firstArg;
      if (app.spaceId !== undefined) {
        fetch(`https://api.reeba.io/items/spaces/${app.spaceId}`)
          .then((res) => res.json())
          .then((res) => {
            const primaryColor = res.data.primary_color;
            if (typeof primaryColor === "string" && primaryColor.length > 0) {
              setTheme(
                createTheme({
                  palette: {
                    primary: {
                      main: primaryColor,
                    },
                  },
                })
              );
            }
            const image = res.data.loading_image;
            if (typeof image === "string" && image.length > 0) {
              setImgSrc(`https://api.reeba.io/assets/${image}`);
            } else {
              setImgSrc("/logo.svg");
            }
          });
      } else {
        setImgSrc("/logo.svg");
      }
    } else {
      if (extWindow.ue === undefined) {
        console.error();
      }
    }
    setupPixelStreamingActions(
      setCustomizeObject,
      setRootAlignRight,
      setLoading,
      setServerStartTime
    );
  }, [app]);
  let child;
  if (customizeObject !== null) {
    child = (<Box/>);
    /*
child = (
      <CustomizeObject
        data={customizeObject}
        isRootAlignRight={isRootAlignRight}
      />
    );*/
  } else if (isLoading) {
    child = renderBox([renderImage(imgSrc), <CircularProgress />]);
  } else if (serverStartTime !== null) {
    const averageStartupTime = 30 * 1000;
    child = renderBox([
      renderImage(imgSrc),
      <CircularStatic time={averageStartupTime} />,
    ]);
  } else if (isWaitingForAvatarSelection) {
    child = (
      <Button
        variant="contained"
        size="large"
        sx={{
          position: "absolute",
          bottom: "50px",
          right: "50px",
        }}
        onClick={() => {
          setLoading(true);
          setWaitingForAvatarSelection(false);
          if (app === undefined || app.spaceId === undefined) {
            console.error();
            return;
          }
          const userId = props.userId;
          if (userId === undefined) {
            console.error();
            return;
          }
          const windowExt = window as any;
          windowExt.emitUIInteraction({
            event: "load_main_3d_space",
            server_ip:
              "3.125.23.168:60" +
              app.spaceId.toString() +
              config.avatarsPortEnding, // TODO maybe get server ip from somewhere fancy
            user_id: userId,
          });
          window.setTimeout(() => {
            setLoading(false);
            const button = document.getElementById("ree-unmute-button");
            if (button !== null) {
              button.style.display = "block";
            } else {
              console.error();
            }
          }, 4000);
        }}
      >
        Next
      </Button>
    );
  } else if (app?.name === "Feydom") {
    child = <SofaIndex setLoading={setLoading} />;
  } else if (app?.link === "reeba-configurator") {
    child = <WindowIndex />;
  } else {
    child = (
      <Box
        sx={{
          width: "0",
          height: "0",
        }}
      ></Box>
    );
  }
  return <ThemeProvider theme={theme}>{child}</ThemeProvider>;
}
